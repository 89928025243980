

























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import CoefficientService from "@/services/CoefficientService";
import UnitService from "@/services/UnitService";
import OperationTypeService from "@/services/OperationTypeService";
import Operation from "@/models/Operation";
import { Guid } from "guid-typescript";
import OperationService, {
  bulkDeleteOperationRequest,
} from "@/services/OperationService";
import {
  BopModelEnum,
  CoefficientTypeEnum,
  OperationType,
  RubriqueEnum,
  UserRoleEnum,
} from "@/utils/Enums";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import Constants from "@/utils/Constants";
import ExportService from "@/services/ExportService";
import * as lodash from "lodash";
import table from "@/utils/table";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};
const MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY = "montageDemontage";
const FOURNITURES_OPERATION_FAMILY_KEY = "fournitures";
@Component({
  components: {
    // ImportBopModelModal,
    Table: () => import("@/components/Shared/Table/Table.vue"),
    TableApi: () => import("@/components/Shared/Table/TableApi.vue"),
    SitesTypeTravauxTableModal: () =>
      import("@/components/Shared/Table/SitesTypeTravauxTableModal.vue"),
    OperationTypeTableModal: () =>
      import("@/components/Shared/Table/OperationTypeTableModal.vue"),
  },
})
export default class OperationsSetup extends Vue {
  @Prop({ required: true }) public bopModelId!: string;
  @Prop({ required: true }) public operationKey!: string;
  @Prop({ required: true }) public typeTravauxFilter!: Array<string>;
  @Prop() public bopModelKey!: string;
  public data: Data = {
    columns: [],
    rows: [],
  };
  public dataFournitures: Data = {
    columns: [],
    rows: [],
  };
  public showModal: boolean = false;
  private duplicationColumns: Array<Object> = [];
  private refreshKey: string = "";
  private isLoaded: boolean = false;
  private url: string = "";
  private urlFournitures: string = "";
  private modifiedOperations: Array<Object> = [];
  private modifiedOperationsFournitures: Array<Object> = [];
  private modifiedDuplicationData: Array<Object> = [];
  private initialDuplicationData: Array<Object> = [];
  private refreshKeyDuplicationTable: string = "";
  private availableCoefficients: any = {};
  private propsSitesTypeTravauxModal: any = false;
  private showSitesTypeTravauxModal: boolean = false;
  private propsOperationTypesModal: any = false;
  private showOperationTypesModal: boolean = false;
  private operationTypes: any = {};
  private fournituresOperationFamilies: any = {};
  private canShowDuplicationPanel: boolean = false;
  private isDuplicateCallerFourniture: boolean = false;
  private readonly bulkId: string = Guid.create().toString();
  private readonly fournitureBulkId: string = Guid.create().toString();
  private readonly columnsToFilter: Array<string> = ["code", "name"];
  private readonly selectAllElementId: string = "selectAll";
  private readonly selectAllFournituresElementId: string =
    "selectAllFournitures";

  get isNotAllowedToEdit(): Boolean {
    if (OperationType[this.operationKey] !== OperationType.OperationDiverse) {
      return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
    } else {
      return !this.$store.getters.isAllowed([
        UserRoleEnum.centralAdministrator,
        UserRoleEnum.siteAdministrator,
        UserRoleEnum.countryAdministrator,
      ]);
    }
  }

  get isNotAllowedToImportExport(): Boolean {
    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
  }

  private get exportBopModelUrl(): string {
    return ExportService.exportBopModelUrl(this.bopModelKey, this.$i18n.locale);
  }

  private get operationType(): number {
    return this.operationKey === RubriqueEnum.OperationPrincipal
      ? OperationType.OperationPrincipal
      : OperationType.OperationDiverse;
  }

  private get canUseApiMode(): boolean {
    return (
      this.operationType === OperationType.OperationPrincipal &&
      (this.isTuyauteriePlastique ||
        this.isCalorifugAppareilBopModel ||
        this.isElectriciteInstrumentation)
    );
  }

  private get isCalorifugAppareilBopModel() {
    return this.bopModelKey === BopModelEnum.CalorifugageAppareil;
  }

  private get isElectriciteInstrumentation() {
    return this.bopModelKey === BopModelEnum.ElectriciteInstrumentation;
  }

  private get isTuyauteriePlastique() {
    return this.bopModelKey === BopModelEnum.TuyauteriePlastique;
  }

  private get canShowFournitures() {
    return (
      this.isCalorifugAppareilBopModel &&
      this.operationType === OperationType.OperationPrincipal
    );
  }

  // Open modal based on property
  public openModal(props: any) {
    this.showSitesTypeTravauxModal = true;
    this.propsSitesTypeTravauxModal = props;
  }

  // Open modal based on property
  public openOperationTypesModal(props: any) {
    this.showOperationTypesModal = true;
    this.propsOperationTypesModal = props;
  }

  public validate(obj: any) {
    (<any>this.$refs.table).modify(obj);
    this.showSitesTypeTravauxModal = false;
  }

  public validateOperationType(obj: any) {
    (<any>this.$refs.table).modify(obj);
    this.showOperationTypesModal = false;
  }

  public validateDuplicate(obj: any) {
    this.validateDuplicationCommon(obj).then(() => {
      this.showSitesTypeTravauxModal = false;
    });
  }

  public validateOperationTypeDuplicate(obj: any) {
    this.validateDuplicationCommon(obj).then(() => {
      this.showOperationTypesModal = false;
    });
  }

  public async addData() {
    const availableCoeffs = JSON.parse(
      JSON.stringify(this.availableCoefficients)
    );
    const newOperation = new Operation(
      availableCoeffs,
      this.bopModelId,
      this.operationType
    );
    if (
      this.isCalorifugAppareilBopModel &&
      this.operationKey === RubriqueEnum.OperationPrincipal
    ) {
      let mainOperationFamily: any = null;
      for (let i in this.operationTypes) {
        const operationType = this.operationTypes[i];
        if (operationType.key === "montageDemontage") {
          mainOperationFamily = operationType;
          break;
        }
      }
      newOperation.operationFamilyId = mainOperationFamily.id;
      newOperation.operationFamilyPath = mainOperationFamily.id;
      newOperation.operationFamilyName = mainOperationFamily.name;
    }
    (<any>this.$refs.table).prependRow(newOperation);
    (<any>this.$refs.table).modify(newOperation);
  }

  public async refresh() {
    this.modifiedOperations = [];
    (<any>this.$refs.table).refreshAPI();
    if (this.operationKey == RubriqueEnum.OperationDiverse) {
      await this.loadUsedSiteTypeTravaux();
    }
  }

  /**
   * hooks
   */

  public created() {
    this.loadData().then(() => {
      this.isLoaded = true;
      this.bindSelectAll();
    });
  }

  public onPaginationChangePage() {
    const selectAllChb: any = document.getElementById(this.selectAllElementId);
    selectAllChb.checked = (<any>this.$refs.table).isBulkAllSelected();
  }

  public onFourniturePaginationChangePage() {
    const selectAllFournituresChb: any = document.getElementById(
      this.selectAllFournituresElementId
    );
    selectAllFournituresChb.checked = (<any>(
      this.$refs.tableFourniture
    )).isBulkAllSelected();
  }

  private async validateDuplicationCommon(obj: any) {
    await this.$nextTick();
    const objTrackId = table.findByPath(obj, "trackId");
    const foundIndex = this.modifiedDuplicationData.findIndex(
      (el) => table.findByPath(el, "trackId") === objTrackId
    );
    this.modifiedDuplicationData[foundIndex] = obj;
    (<any>this.$refs.tableDuplication).modify(obj);
  }

  private async handleBulkDelete(rowDataArray: Array<any>) {
    const modalResponse = await this.deleteConfirm(
      this.$t("bopModelSetup.confirmBulkDeleteOperationMessage").toString()
    );
    if (modalResponse) {
      let operationsToDelete: Array<bulkDeleteOperationRequest> = [];
      if (this.operationKey === RubriqueEnum.OperationDiverse) {
        operationsToDelete = rowDataArray.map((rowData) => {
          let result: bulkDeleteOperationRequest = {
            operationId: rowData.id,
            operationUnitValueIds: rowData.operationSiteTypeTravaux.map(
              (el) => el.id
            ),
          };
          return result;
        });
      } else {
        operationsToDelete = rowDataArray.map((rowData) => {
          let result: bulkDeleteOperationRequest = {
            operationId: rowData.id,
            operationUnitValueIds: [rowData.unitValueId],
          };
          return result;
        });
      }
      try {
        const response = await OperationService.bulkDeleteOperation(
          operationsToDelete,
          this.operationKey === RubriqueEnum.OperationDiverse
        );
        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.operations.deleteSuccessTitle"
            ).toString(),
            text: this.$t("bopModelSetup.operations.deleteSuccess").toString(),
          });

          this.refreshAll();
        }
      } catch (err) {
        const errResponse = err.response;
        let text = this.$t("bopModelSetup.operations.deleteError").toString();
        if (
          errResponse.status === 500 &&
          errResponse.data.code === "InstanciatedInBop"
        ) {
          text = this.$t("bopModelSetup.operations.bulkDeleteErrorUsedInBop")
            .toString()
            .replace("{operationCode}", errResponse.data.operationCode);
        }
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(
            "bopModelSetup.operations.deleteErrorTitle"
          ).toString(),
          text,
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
  }

  private async cancelDuplicate() {
    this.canShowDuplicationPanel = false;
    this.modifiedDuplicationData = [];
    this.initialDuplicationData = [];
    this.refreshKeyDuplicationTable = Guid.create().toString();
    await this.loadUsedSiteTypeTravaux();
  }

  private async removeDuplicate(data) {
    const confirm = await this.deleteConfirm(
      this.$t("bopModelSetup.confirmDeleteDuplicateMessage").toString()
    );
    if (confirm) {
      this.modifiedDuplicationData.splice(data.rowIndex, 1);
      (<any>this.$refs.tableDuplication).deleteRow(data.rowIndex);
      if (!this.modifiedDuplicationData.length) {
        await this.cancelDuplicate();
      }
    }
  }

  private async saveDuplicate() {
    let response: boolean = false;
    if (this.isDuplicateCallerFourniture) {
      response = await this.save([], this.modifiedDuplicationData);
    } else {
      response = await this.save(this.modifiedDuplicationData, []);
    }
    if (response) {
      await this.cancelDuplicate();
      this.refreshAll();
    }
  }

  /**
   * Handle duplicate by generating duplicate table based on the caller tableColumns
   * @param rowDataArray
   * @param tableColumns
   * @param isFourniture
   * @private
   */
  private handleBulkDuplicate(
    rowDataArray: Array<any>,
    tableColumns: Array<any>,
    isFourniture: boolean = false
  ) {
    const duplicationColumns = lodash.cloneDeep(tableColumns);
    const bulkColumnIndex = duplicationColumns.findIndex(
      (c) => c.name === "bulkActionsCheckbox"
    );
    const sitesTypeTravauxColumnIndex = duplicationColumns.findIndex(
      (c) => c.name === "sitesTypeTravaux"
    );
    if (sitesTypeTravauxColumnIndex !== -1) {
      duplicationColumns[sitesTypeTravauxColumnIndex].isDuplicationField = true;
    }
    const operationTypeColumnIndex = duplicationColumns.findIndex(
      (c) => c.name === "operationType"
    );
    if (operationTypeColumnIndex !== -1) {
      duplicationColumns[operationTypeColumnIndex].isDuplicationField = true;
    }
    if (bulkColumnIndex !== -1) {
      duplicationColumns.splice(bulkColumnIndex, 1);
    }
    this.isDuplicateCallerFourniture = isFourniture;
    this.duplicationColumns = duplicationColumns;
    this.$nextTick().then(() => {
      const initialDuplicationData = rowDataArray.map((el) =>
        isFourniture ? this.duplicateDataFourniture(el) : this.duplicateData(el)
      );
      this.initialDuplicationData = initialDuplicationData;
      initialDuplicationData.forEach((o) => {
        (<any>this.$refs.tableDuplication).modify(o);
      });
      this.initialDuplicationData = rowDataArray;
      this.canShowDuplicationPanel = true;
    });
  }

  private refreshAll() {
    this.refreshKey = Guid.create().toString();
    this.$nextTick(() => {
      this.bindSelectAll();
    });
  }

  private bulkChecboxBuilder(id: string): string {
    return `<div class="bulk-selectAll"><input type="checkbox" style="accent-color:#188d6d;margin-left: -3px;"  id="${id}" /></div>`;
  }

  /**
   * Methods
   */
  private handleImport() {
    (<any>this.$parent).handleImport();
  }
  private afterSearch() {
    this.bindSelectAll();
  }
  private bindSelectAll() {
    const selectAllChb = document.getElementById(this.selectAllElementId);
    const selectAllFournituresChb = document.getElementById(
      this.selectAllFournituresElementId
    );
    this.selectAllBinder(selectAllChb, this.bulkId);
    this.selectAllBinder(selectAllFournituresChb, this.fournitureBulkId);
  }

  private selectAllBinder(selectAllChb: HTMLElement | null, bulkId: string) {
    if (!selectAllChb) return;

    const selectAllChbCloned = selectAllChb.cloneNode(true);
    selectAllChb.parentNode?.replaceChild(selectAllChbCloned, selectAllChb);

    selectAllChbCloned.addEventListener("change", (event) => {
      if (bulkId === this.bulkId) {
        (<any>this.$refs.table).bulkSelectAll();
      } else {
        (<any>this.$refs.tableFourniture).bulkSelectAll();
      }
    });
  }

  private async loadData() {
    const units = (await UnitService.getUnits()).data;
    let operationTypes: any = {};
    let fournituresOperationFamilies: any = {};
    const allOperationTypes: any = ({} = (
      await OperationTypeService.ListOperationTypesWithDescendenants(
        this.operationType,
        this.bopModelId,
        this.$i18n.locale
      )
    ).data);
    if (this.isCalorifugAppareilBopModel) {
      for (let i in allOperationTypes) {
        const op = allOperationTypes[i];
        if (op.key === FOURNITURES_OPERATION_FAMILY_KEY) {
          fournituresOperationFamilies[op.id] = op;
        } else {
          operationTypes[op.id] = op;
        }
      }
    } else {
      operationTypes = allOperationTypes;
    }
    this.operationTypes = operationTypes;
    this.fournituresOperationFamilies = fournituresOperationFamilies;
    let coeffs: Array<any> = [];
    // loading coefficients and operations
    if (this.operationKey == RubriqueEnum.OperationPrincipal) {
      coeffs = (
        await CoefficientService.getOperationPrincipaleCoefficients(
          this.bopModelId,
          this.$i18n.locale
        )
      ).data;

      this.url = OperationService.getOperationsPrincipalesTableUrl(
        this.bopModelId,
        this.$i18n.locale,
        this.isCalorifugAppareilBopModel
          ? MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY
          : "",
        this.canUseApiMode
      );
    } else {
      this.url = OperationService.getOperationsDiversesTableUrl(
        this.bopModelId,
        this.typeTravauxFilter,
        this.$i18n.locale
      );
    }
    let dataColumns: Array<any> = [
      {
        name: "bulkActionsCheckbox",
        title: this.bulkChecboxBuilder(this.selectAllElementId),
        isBulkTaskCheckBox: true,
        bulkId: this.bulkId,
      },
      {
        name: "operationType",
        title: this.$t("bopModelSetup.operationType"),
        property: "operationFamilyId",
        sortField: "operationFamilyName",
        display: "operationFamilyName",
        options: operationTypes,
      },
      {
        sortField: "code",
        name: "editable",
        title: this.$t("bopModelSetup.operationCode"),
        property: "code",
      },
      {
        sortField: "operation",
        name: "editable",
        title: this.$t("bopModelSetup.operationName"),
        property: "name",
      },
    ];
    let fournitureEpaisseur: any = {};
    let coeffCOnfigOptions: any = {};
    let isType1Coefficients: boolean = false;

    for (let i = 0; i < coeffs.length; i++) {
      const coeff = coeffs[i];
      isType1Coefficients = coeff.coefficientType == CoefficientTypeEnum.T1;
      if (coeff.definesOperationUnitPoint) {
        coeffCOnfigOptions[coeff.id] = (
          await CoefficientOptionService.GetCoefficientOptionsByCoefficient(
            coeff.id,
            this.$i18n.locale
          )
        ).data;
      }
    }
    coeffs.forEach((coeff: any) => {
      // adding coefficients columns definition
      if (coeff.definesOperationUnitPoint) {
        const coeffConfigs: any = coeffCOnfigOptions[coeff.id];
        const textField =
          coeff.coefficientType == CoefficientTypeEnum.T2 ? "value" : "name";
        let coefficientColumn: any = {
          name: "coeffOptiondropdown",
          title: coeff.name,
          property: `coefficients.${coeff.key}.coefficientOptionId`,
          display: `coefficients.${coeff.key}.name`,
          options: coeffConfigs,
          coeffKey: coeff.key,
          checkField: "value",
          valueField: "id",
          textField,
        };
        if (!this.canUseApiMode) {
          coefficientColumn.sortField = `coefficients.${coeff.key}.name`;
        }

        if (this.isCalorifugAppareilBopModel && coeff.key === "epaisseur") {
          fournitureEpaisseur = coefficientColumn;
        } else {
          dataColumns.push(coefficientColumn);
        }
        this.availableCoefficients[coeff.key] = {
          id: Guid.createEmpty().toString(),
          coefficientId: coeff.id,
        };
      } else {
        dataColumns.push({
          name: "editable",
          sortField: `coefficients.${coeff.key}.value`,
          title: coeff.name,
          property: `coefficients.${coeff.key}.value`,
        });
        this.availableCoefficients[coeff.key] = {
          id: Guid.createEmpty().toString(),
          coefficientId: coeff.id,
          value: "0",
        };
      }
    });
    if (!isType1Coefficients) {
      dataColumns.push({
        sortField: "unitValue",
        name: "editable",
        title: this.$t("bopModelSetup.unitValue"),
        property: "unitValue",
      });
    }
    dataColumns.push(
      ...[
        {
          name: "dropdown",
          title: this.$t("bopModelSetup.unit"),
          sortField: "unit.symbol",
          property: "unit.id",
          display: "unit.symbol",
          options: units,
          valueField: "key",
          textField: "value",
        },
      ]
    );

    if (this.operationKey == RubriqueEnum.OperationPrincipal) {
      dataColumns.push({
        name: "static",
        title: this.$t("bopModelSetup.sites"),
        staticData: this.$t("bopModelSetup.allSites"),
        staticClass: "badge badge-secondary p-2",
      });
    } else {
      dataColumns.push({
        name: "sitesTypeTravaux",
        sortField: "operationSiteTypeTravaux",
        title: this.$t("bopModelSetup.sites"),
        property: "operationSiteTypeTravaux",
        rootId: "id",
      });
    }
    dataColumns.push({
      name: "actions",
      title: "",
      removeButton: true,
      editButton: false,
      duplicateButton: true,
      disableRemoveButton: this.isNotAllowedToEdit,
      disableEditButton: this.isNotAllowedToEdit,
      disableDuplicateButton: this.isNotAllowedToEdit,
    });

    this.data.columns = dataColumns;
    if (this.canShowFournitures) {
      this.urlFournitures = OperationService.getOperationsPrincipalesTableUrl(
        this.bopModelId,
        this.$i18n.locale,
        FOURNITURES_OPERATION_FAMILY_KEY
      );
      this.dataFournitures.columns = [
        {
          name: "bulkActionsCheckbox",
          title: this.bulkChecboxBuilder(this.selectAllFournituresElementId),
          isBulkTaskCheckBox: true,
          bulkId: this.fournitureBulkId,
        },
        {
          sortField: "code",
          name: "editable",
          title: this.$t("bopModelSetup.operationFournitureCode"),
          property: "code",
        },
        {
          sortField: "code",
          name: "editable",
          title: this.$t("bopModelSetup.operationFournitureName"),
          property: "name",
        },
        fournitureEpaisseur,
        {
          sortField: "unitValue",
          name: "editable",
          title: this.$t("bopModelSetup.unitValue"),
          property: "unitValue",
        },
        {
          name: "dropdown",
          title: this.$t("bopModelSetup.unit"),
          sortField: "unit.symbol",
          property: "unit.id",
          display: "unit.symbol",
          options: units,
          valueField: "key",
          textField: "value",
        },
        {
          name: "operationType",
          title: this.$t("bopModelSetup.operationType"),
          property: "operationFamilyId",
          sortField: "operationFamilyName",
          display: "operationFamilyName",
          options: fournituresOperationFamilies,
        },
        {
          name: "static",
          title: this.$t("bopModelSetup.sites"),
          staticData: this.$t("bopModelSetup.allSites"),
          staticClass: "badge badge-secondary p-2",
        },
        {
          name: "actions",
          title: "",
          removeButton: true,
          editButton: false,
          duplicateButton: true,
          disableRemoveButton: this.isNotAllowedToEdit,
          disableEditButton: this.isNotAllowedToEdit,
          disableDuplicateButton: this.isNotAllowedToEdit,
        },
      ];
    }
    if (this.operationKey == RubriqueEnum.OperationDiverse) {
      await this.loadUsedSiteTypeTravaux();
    }
  }

  /**
   * Methods
   */
  private addFourniture() {
    const newOperation = new Operation({}, this.bopModelId, this.operationType);
    const mainOperationFamily: any = Object.values(
      this.fournituresOperationFamilies
    )[0];
    newOperation.operationFamilyId = mainOperationFamily.id;
    newOperation.operationFamilyPath = mainOperationFamily.id;
    newOperation.operationFamilyName = mainOperationFamily.name;
    (<any>this.$refs.tableFourniture).updatedRows.unshift(newOperation);
    (<any>this.$refs.tableFourniture).modify(newOperation);
  }
  private async saveAll() {
    await this.save(
      this.modifiedOperations,
      this.modifiedOperationsFournitures
    );
  }
  private async save(
    modifiedOperations: Array<any>,
    modifiedOperationsFournitures: Array<any>
  ): Promise<boolean> {
    let checkOperations = true;
    if (modifiedOperations.length) {
      checkOperations = await this.saveOperation(modifiedOperations);
      if (checkOperations) {
        const selectAllChb: any = document.getElementById(
          this.selectAllElementId
        );
        selectAllChb.checked = false;

        await this.refresh();
      }
    }
    let checkFournitures = true;
    if (
      this.isCalorifugAppareilBopModel &&
      modifiedOperationsFournitures.length
    ) {
      checkFournitures = await this.saveOperation(
        modifiedOperationsFournitures
      );
      if (checkFournitures) {
        this.modifiedOperationsFournitures = [];
        const selectAllFournituresChb: any = document.getElementById(
          this.selectAllFournituresElementId
        );
        selectAllFournituresChb.checked = false;
        (<any>this.$refs.tableFourniture).refreshAPI();
      }
    }

    this.$store.commit(
      "changeHasBeenModified",
      !(checkOperations && checkFournitures)
    );

    return checkOperations && checkFournitures;
  }

  private duplicateDataFourniture(data: any) {
    data.operationSiteTypeTravaux = [];
    data.unitValueId = null;
    data.id = Guid.createEmpty().toString();
    return data;
  }

  private async saveOperation(
    modifiedOperations: Array<Object>
  ): Promise<boolean> {
    if (modifiedOperations.length) {
      try {
        const response = await OperationService.saveOperations(
          modifiedOperations,
          this.$i18n.locale
        );
        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.operations.saveSuccessTitle"
            ).toString(),
            text: this.$t("bopModelSetup.operations.saveSuccess").toString(),
          });
          return true;
        }
      } catch (err) {
        if (err.response.status === 400) {
          let text = this.$t(
            "bopModelSetup.operations.incorrectValue"
          ).toString();
          switch (err.response.data) {
            case "invalidInputValue":
              text = this.$t(
                "bopModelSetup.operations.errors.invalidInputValue"
              ).toString();
              break;
            case "NoSiteSelected":
              text = this.$t(
                "bopModelSetup.operations.errors.NoSiteSelected"
              ).toString();
              break;
            case "duplicateSiteTypeTravaux":
              text = this.$t(
                "bopModelSetup.operations.errors.duplicateSiteTypeTravaux"
              ).toString();
              break;
            case "duplicateSelectedCoefficentOptions":
              text = this.$t(
                "bopModelSetup.operations.errors.duplicateCoefficientConfigValues"
              ).toString();
              break;
            case "duplicateCodeOperation":
              text = this.$t(
                "bopModelSetup.operations.errors.duplicateCodeOperation"
              ).toString();
              break;
          }
          this.$notify({
            group: "globalError",
            type: "error",
            title: this.$t("bopModelSetup.operations.errors.title").toString(),
            text,
            duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
          });
        }
      }
    }
    return false;
  }

  private async removeOperation(data) {
    const check = await this.deleteOperation(data);
    if (check) {
      (<any>this.$refs.table).deleteRow(data.rowIndex);
      this.bindSelectAll();
    }
  }

  private async removeFourniture(data) {
    const check = await this.deleteOperation(data);
    if (check) {
      (<any>this.$refs.tableFourniture).deleteRow(data.rowIndex);
      this.bindSelectAll();
    }
  }

  private deleteConfirm(message: string) {
    return this.$bvModal.msgBoxConfirm(message, {
      title: this.$t("bopModelSetup.confirmDelteOperation.title").toString(),
      size: "lg",
      buttonSize: "lg",
      okVariant: "primary",
      cancelVariant: "secondary",
      okTitle: this.$t("bopModelSetup.confirmDelteOperation.yes").toString(),
      cancelTitle: this.$t("bopModelSetup.confirmDelteOperation.no").toString(),
      footerClass: "p-2",
      hideHeaderClose: false,
      centered: true,
      dialogClass: "modal-app",
    });
  }

  private async deleteOperation(data: any): Promise<boolean> {
    const modalResponse = await this.deleteConfirm(
      this.$t("bopModelSetup.confirmDelteOperation.message").toString()
    );
    if (modalResponse) {
      const rowData = data.rowData;
      let operationUnitValueIds: string[] = [];
      if (this.operationKey === RubriqueEnum.OperationDiverse) {
        operationUnitValueIds = rowData.operationSiteTypeTravaux.map(
          (el) => el.id
        );
      } else {
        operationUnitValueIds.push(
          rowData.unitValueId ?? Guid.createEmpty().toString()
        );
      }
      try {
        const response = await OperationService.deleteOperation(
          rowData.id,
          operationUnitValueIds,
          this.operationKey === RubriqueEnum.OperationDiverse
        );
        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.operations.deleteSuccessTitle"
            ).toString(),
            text: this.$t("bopModelSetup.operations.deleteSuccess").toString(),
          });
          return true;
        }
      } catch (err) {
        const errResponse = err.response;
        let text = this.$t("bopModelSetup.operations.deleteError").toString();
        if (
          errResponse.status === 500 &&
          errResponse.data.detail === "instanciatedInBop"
        ) {
          text = this.$t(
            "bopModelSetup.operations.deleteErrorUsedInBop"
          ).toString();
        }
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(
            "bopModelSetup.operations.deleteErrorTitle"
          ).toString(),
          text,
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
    return false;
  }

  private duplicateData(data: any) {
    data.operationSiteTypeTravaux = [];
    data.unitValueId = null;
    data.id = Guid.createEmpty().toString();
    for (let i in data.coefficients) {
      data.coefficients[i].id = Guid.createEmpty().toString();
      data.coefficients[i].value = 0;
    }
    return data;
  }

  private async loadUsedSiteTypeTravaux() {
    this.$store.commit("resetUsedSitesTypeTravaux");

    const usedSitesTypeTravaux = (
      await OperationService.getUsedSiteTypeTravauxByBopModelId(this.bopModelId)
    ).data;
    usedSitesTypeTravaux.forEach((el: any) => {
      const rowData = {
        siteId: el.siteId,
        operationCode: el.operationCode,
        typeTravauxId: el.typeTravauxId,
        id: el.id,
      };
      this.$store.commit("changeUsedSitesTypeTravaux", rowData);
    });
  }

  /**
   * Watchers
   */
}
